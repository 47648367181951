import { v4 } from 'uuid'
import type { AppEvent, AppMessage, PostMessageEvent } from './partials/types'

export const useApp = () => {
  const message: AppMessage = <P, R = void>(
    eventName: string,
    payload?: P,
  ): Promise<R> => {
    const id = v4()

    /**
     * FIX: Unresolved promise
     * If we close the nfc native popup, we have a problem coz message wouldn't be called and promise wouldn't be resolved
     * That's why we faced the issue with impossible `Cancel` on `Processing` when trying to connect band to refugee
     */
    return new Promise<R>((resolve, reject) => {
      const onMessage = (e: MessageEvent): void => {
        const { event, eventId, payload, error } = JSON.parse(
          e.data,
        ) as AppEvent<R>

        if (eventId !== id) return

        if (event === `${eventName}:success`) {
          resolve(payload)
        } else if (event === `${eventName}:failed`) {
          reject(new Error(error))
        }

        window.removeEventListener('message', onMessage, true)
      }

      if (window.ReactNativeWebView) {
        window.addEventListener('message', onMessage, true)

        const event: PostMessageEvent<P> = {
          event: eventName,
          eventId: id,
          payload,
        }

        window.ReactNativeWebView.postMessage(JSON.stringify(event))
      } else {
        reject(new Error('NotInsideApp'))
      }
    })
  }

  return {
    isSupported: () => !!window.ReactNativeWebView,
    message,
  }
}
